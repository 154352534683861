<template>
  <b-card>
    <b-card-text v-if="rows > 0">
      Results {{ from }} - {{ to }} / {{ rows }}
    </b-card-text>
    <!-- pagination -->
    <b-pagination
      v-model="selectedPage"
      hide-goto-end-buttons
      :per-page="perPage"
      :total-rows="rows"
      first-number
      last-number
      :limit="10"
      @change="onChange"
    />
  </b-card>
</template>

<script>
import { BPagination, BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BPagination,
    BCardText,
  },
  props: ['totalRows', 'currentPage', 'eventHub', 'perPage'],
  data() {
    return {
      hub: null,
      selectedPage: 1,
      rows: 0,
      from: 0,
      to: 0,
      totalEntries: 0,
    }
  },
  created() {
    if (this.$props.totalRows) {
      this.rows = this.$props.totalRows
    }
    if (this.$props.currentPage) {
      this.selectedPage = this.$props.currentPage
    }
    if (this.$props.eventHub) {
      this.hub = this.$props.eventHub
    }
    this.hub.$on('updateMeta', this.handleUpdateMeta)
  },
  beforeDestroy() {
    this.hub.$off('updateMeta', this.handleUpdateMeta)
  },
  methods: {
    handleUpdateMeta(meta) {
      this.rows = meta.total
      this.rowsPerPage = meta.per_page
      this.selectedPage = meta.current_page
      this.from = meta.from
      this.to = meta.to
    },
    onChange(page) {
      this.hub.$emit('pageChange', page)
    },
  },
}
</script>
