<template>
  <div>
    <edit-parcel-items
      v-if="selectedEditParcel"
      id="edit-parcel-items"
      :parcel="selectedEditParcel"
      :event-hub="eventHub"
      :declaration="computedDeclarationData"
      :shipment="shipment"
      :readonly="!canEdit"
    />
    <status-information
      v-if="informationItem"
      :item="informationItem"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Parcels need additional information. Please add comment and attachment(s).
        </b-card-text>
      </b-col>
      <b-col
        class="text-right"
      >
        <b-button
          variant="outline-success"
          class="m-1"
          :href="excelDownloadUrl"
        >
          Download as Excel
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="parcels"
            responsive
            :fields="fields"
            :busy="loading"
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template #cell(actions)="data">
              <view-parcel-button
                v-if="!canEdit"
                :declaration="data.item.low_value_declaration"
                :parcel="data.item"
                :event-hub="eventHub"
              />
              <b-button
                v-if="canEdit"
                v-b-modal.edit-parcel-items
                type="button"
                variant="warning"
                @click="editDeclaration(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(descriptions)="data">
              <div>
                {{ data.item.parcel.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
              </div>
            </template>

            <template #cell(hs_codes)="data">
              <div>
                <b-row
                  v-for="(parcelItem, index) in data.item.parcel.items"
                  :key="index"
                >
                  <span
                    v-if="getItemError(index, data.item.status_info, parcelItem.hs_code)"
                    style="color: #780000; font-weight: bold;"
                  >{{ parcelItem.hs_code }}</span>
                  <span v-if="!getItemError(index, data.item.status_info, parcelItem.hs_code)">{{ parcelItem.hs_code }}</span>
                </b-row>
              </div>
            </template>

            <template #cell(value)="data">
              <div
                v-if="data.item.parcel.items.length > 0"
                class="text-right"
              >
                {{ data.item.parcel.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
              </div>
            </template>

            <template #cell(status_info)="data">
              <div v-if="data.item.status_info !== null && data.item.status_info['errors']">
                <span
                  v-b-modal.modal-status-information
                  class="text-primary"
                  @click="informationItem = data.item"
                >
                  Show errors
                  <b-badge
                    variant="light"
                    class="text-primary text-nowrap"
                  >
                    {{ data.item.status_info['errors'].length }}
                  </b-badge>
                </span>
              </div>
            </template>

          </b-table>
        </div>
        <pagination
          v-show="!loading"
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BRow,
  BTable,
  BButton,
  BSpinner,
  BBadge,
} from 'bootstrap-vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import StatusInformation from '@/views/shipments/declarations/modals/StatusInformation.vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import Vue from 'vue'
import { searchQuery } from '@core/utils/filter'

export default {
  components: {
    ViewParcelButton,
    BBadge,
    StatusInformation,
    Pagination,
    BCardText,
    BCol,
    BRow,
    BTable,
    BButton,
    BSpinner,
    EditParcelItems,
  },
  props: {
    eventHub: {
      default: () => new Vue(),
    },
    shipment: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    declarationType: {
      type: String,
      default: 'h7',
    },
  },
  data() {
    return {
      loading: false,
      informationItem: null,
      page: 1,
      perPage: 100,
      selectedEditParcel: null,
      selectedEditDeclaration: null,
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.shipment.company.id}/shipments/${this.shipment.id}/declarations/${this.declarationType}/needsDocuments/excel`,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code' },
        { key: 'container_code', label: 'Container' },
        { key: 'descriptions', label: 'Description' },
        { key: 'hs_codes', label: 'HS code' },
        { key: 'value', label: 'Value' },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'status_info', label: 'Errors' },
      ],
      parcels: [],
    }
  },
  computed: {
    canEdit() {
      return this.$permissions().hasPermission('edit shipment')
    },
    computedDeclarationData() {
      if (this.selectedEditDeclaration !== null) {
        return this.selectedEditDeclaration
      }
      return null
    },
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/needs-documents`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', this.updateParcelItems)
    this.eventHub.$on('general-declaration-update', this.fetchData)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.fetchData()
  },
  mounted() {
    this.eventHub.$on('renewDeclarationList', this.handleRenewDeclarationList)
    this.eventHub.$on('pageChange', this.handlePageChange)
  },
  beforeDestroy() {
    this.eventHub.$off('update-parcel-items', this.updateParcelItems)
    this.eventHub.$off('general-declaration-update', this.fetchData)
    this.eventHub.$off('set-view-parcel', this.viewParcel)
    this.eventHub.$off('renewDeclarationList', this.handleRenewDeclarationList)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    handleRenewDeclarationList() {
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    getItemError(index, errorList, hsCode) {
      let result = false
      if (errorList !== null) {
        errorList.errors.forEach(error => {
          if (error.item_reference && parseInt(error.item_reference, 10) === index + 1) {
            result = error
          } else if (hsCode && error.declaration_value === hsCode && error.field === 'parcel_item.hs_code') {
            result = error
          }
        })
      }

      return result
    },
    updateParcelItems(parcelId) {
      this.$http.put(`/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations`, { parcels: [parcelId] })
      this.selectedEditParcel = null
      this.selectedEditDeclaration = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    viewParcel(declaration, parcel) {
      this.selectedEditDeclaration = declaration
      this.selectedEditParcel = parcel
    },
    editDeclaration(data) {
      this.selectedEditParcel = data.parcel
      this.selectedEditDeclaration = data
    },
    fetchData(activeTab) {
      if ((!activeTab || activeTab === 'needs-documents') && !this.loading) {
        this.loading = true
        this.$http.get(searchQuery(this.url, this.filters))
          .then(response => {
            this.loading = false
            this.parcels = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
  },
}
</script>

<style scoped>

</style>
