<template>
  <div>
    <edit-parcel-items
      v-if="selectedDeclaration || selectedParcel"
      id="edit-parcel-items"
      :parcel="selectedParcel"
      :event-hub="eventHub"
      :filters="filters"
      :declaration="selectedDeclaration"
      :shipment="shipment"
    />
    <status-information
      v-if="informationItem"
      :item="informationItem"
    />
    <b-row>
      <b-col class="mt-2">
        <b-card-text>
          Parcels that are held by customs. This means either additional documents or physical control are needed.
        </b-card-text>
      </b-col>
      <b-col class="text-right">
        <b-button
          variant="outline-success"
          class="m-1"
          :href="excelDownloadUrl"
        >
          Download as Excel
        </b-button>
      </b-col>
      <download-warehouse-excel-button
        :disabled="declarations.length === 0"
        label="Download Excel for Warehouse"
        :shipment-id="shipment.id"
        type="heldByCustoms"
      />
    </b-row>
    <b-row>
      <b-col>
        <div class="overflow-x-scroll">
          <b-table
            :items="declarations"
            responsive
            :fields="fields"
            :busy="loading"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            no-local-sorting
            show-empty
            empty-text="No matching records found"
            class="mb-0"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #head()="data">
              <span class="text-nowrap">
                {{ data.label }}
              </span>
            </template>
            <template #cell(actions)="data">
              <b-button-group>
                <view-parcel-button
                  :declaration="data.item"
                  :parcel="data.item.parcel"
                  :event-hub="eventHub"
                />
                <download-pdf-button
                  :declaration="data.item"
                  :parcel="data.item.parcel"
                  :shipment="shipment"
                />
              </b-button-group>
            </template>

            <template #cell(tracking_number)="data">
              {{ data.item.parcel.tracking_number }}
            </template>

            <template #cell(container_code)="data">
              {{ data.item.parcel.container_code }}
            </template>

            <template #cell(descriptions)="data">
              <div>
                {{ data.item.parcel.items.map(function (parcelItem) {return parcelItem.description}).join(', ') }}
              </div>
            </template>

            <template #cell(hs_codes)="data">
              <div>
                {{ data.item.parcel.items.map(function (parcelItem) {return parcelItem.hs_code}).join(', ') }}
              </div>
            </template>

            <template #cell(value)="data">
              <div
                v-if="data.item.parcel.items.length > 0"
                class="text-right"
              >
                {{ data.item.parcel.items.reduce(function (sum, parcelItem) {return sum + parseFloat(parcelItem.value)}, 0) | formatNumber(2) }}
              </div>
            </template>

            <template #cell(status_info)="data">
              <div v-if="data.item.status_info !== null && data.item.status_info['errors']">
                <span
                  v-b-modal.modal-status-information
                  class="text-primary text-nowrap"
                  @click="informationItem = data.item"
                >
                  Show errors
                  <b-badge
                    variant="light"
                    class="text-primary"
                  >
                    {{ data.item.status_info['errors'].length }}
                  </b-badge>
                </span>
              </div>
            </template>

          </b-table>
        </div>
        <pagination
          v-show="!loading"
          :event-hub="eventHub"
          :per-page="perPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCardText,
  BCol,
  BButton,
  BRow,
  BTable,
  BSpinner, BButtonGroup,
} from 'bootstrap-vue'
import ViewParcelButton from '@/views/shipments/declarations/components/ViewParcelButton.vue'
import DownloadWarehouseExcelButton from '@/views/shipments/declarations/components/classes/DownloadWarehouseExcelButton.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import StatusInformation from '@/views/shipments/declarations/modals/StatusInformation.vue'
import EditParcelItems from '@/views/shipments/declarations/modals/EditParcelItems.vue'
import DownloadPdfButton from '@/views/shipments/declarations/components/DownloadPdfButton.vue'
import { searchQuery } from '@core/utils/filter'

export default {
  components: {
    DownloadPdfButton,
    BButtonGroup,
    EditParcelItems,
    StatusInformation,
    DownloadWarehouseExcelButton,
    ViewParcelButton,
    BCardText,
    BCol,
    BRow,
    BButton,
    BTable,
    BSpinner,
    Pagination,
  },
  props: ['eventHub', 'shipment', 'filters'],
  data() {
    return {
      loading: false,
      informationItem: null,
      page: 1,
      perPage: 100,
      selectedDeclaration: null,
      selectedParcel: null,
      excelDownloadUrl: `${process.env.VUE_APP_API_URL}/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/heldByCustoms/excel`,
      sortBy: null,
      isSortDirDesc: null,
      fields: [
        { key: 'actions', label: 'Actions' },
        { key: 'tracking_number', label: 'Tracking code', sortable: true },
        { key: 'container_code', label: 'Container', sortable: true },
        { key: 'descriptions', label: 'Description' },
        { key: 'hs_codes', label: 'HS code' },
        { key: 'value', label: 'Value' },
        { key: 'lrn', label: 'LRN' },
        { key: 'mrn', label: 'MRN' },
        { key: 'status_info', label: 'Status Information' },
      ],
      declarations: [],
    }
  },
  computed: {
    url() {
      let url = `/v1/companies/${this.$activeCompany().data.company.id}/shipments/${this.shipment.id}/declarations/held-by-customs`
      url += `?page=${this.page}`
      url += `&per_page=${this.perPage}`
      return url
    },
  },
  watch: {
    filters() {
      this.fetchData()
    },
    sortBy() {
      this.fetchData()
    },
    isSortDirDesc() {
      this.fetchData()
    },
  },
  created() {
    this.eventHub.$on('update-parcel-items', this.handleUpdateParcelItems)
    this.eventHub.$on('set-view-parcel', this.viewParcel)
    this.eventHub.$on('renewDeclarationList', this.handleRenewDeclarationList)
    this.fetchData()
  },
  mounted() {
    this.eventHub.$on('pageChange', this.handlePageChange)
  },
  beforeDestroy() {
    this.eventHub.$off('update-parcel-items', this.handleUpdateParcelItems)
    this.eventHub.$off('set-view-parcel', this.viewParcel)
    this.eventHub.$off('renewDeclarationList', this.handleRenewDeclarationList)
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handleUpdateParcelItems() {
      this.selectedDeclaration = null
      this.selectedParcel = null
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    viewParcel(declaration, parcel) {
      this.selectedDeclaration = declaration
      this.selectedParcel = parcel
    },
    handleRenewDeclarationList() {
      this.fetchData()
      this.eventHub.$emit('refresh-statistics')
    },
    handlePageChange(newPage) {
      this.page = newPage
      this.fetchData()
    },
    fetchData() {
      if (!this.loading) {
        this.loading = true
        this.$http.get(searchQuery(this.url, this.filters), {
          params: {
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
          },
        })
          .then(response => {
            this.loading = false
            this.declarations = response.data.data
            this.$props.eventHub.$emit('updateMeta', response.data.meta)
          })
      }
    },
  },
}
</script>
